<template>
  <div>
    <aad-title>Speakers</aad-title>

    <aad-loader v-if="!allSpeakers || allSpeakers.length < 1"></aad-loader>

    <div v-else class="card-deck" id="card-deck-default-sidebar">
      <div
        class="card img-vert-xs"
        v-for="speaker in allSpeakers"
        :key="speaker.id"
      >
        <div class="row hor-card card-sm">
          <router-link
            :to="{ name: 'SpeakerDetails', params: { id: speaker.id } }"
            class="col-3"
          >
            <img
              class="hor-card-img"
              :src="speaker.PhotoURL || getSpeakerPicture(speaker.PersonId)"
              :alt="'Image of ' + speaker.DisplayName"
              @error="fixImage"
            />
          </router-link>
          <div class="col-9 card-body">
            <router-link
              :to="{ name: 'SpeakerDetails', params: { id: speaker.id } }"
              class="card-title card-title-sm"
            >
              {{ speaker.DisplayName }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.getSpeakers();
  },
  metaInfo: {
    title: "Speakers",
  },
  methods: {
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    fixImage(e) {
      // If the image breaks while loading (Very likely), then replace it with the blank
      e.target.src = require("@/assets/loginicon.jpg");
    },
    getSpeakerPicture(speakerId) {
      let meetingCode = this.$store.state.meetingCode;
      let url = `https://lms.aad.org/MeetingSpeaker/GetPhoto?eventId=${meetingCode}&personId=${speakerId}`;
      return url;
    },
  },
  computed: {
    ...mapGetters(["allSpeakers"]),
  },
};
</script>
